import React, { Component } from "react";
import SectionHeader from "../components/Common/SectionHeader.jsx";
import CartCard from "../components/Cart/CartCard.jsx";
import CartSummary from "../components/Cart/CartSummary.jsx";
import FeaturedContainer from "../components/Common/FeaturedContainer.jsx";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      cart: [],
      mem_id: ""
    };
  }
  componentDidMount() {
    // console.log("-----------------");
    // console.log("doesnotexist mounted");
    // console.log("-----------------");

    if (this.props.db_currentUser) {
      console.log("exists");
      this.props.getCart(this.props.db_currentUser);
    }
    window.scrollTo(0, 0);
    this.props.modalToggle(true, ""); //Close modal if open;
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.categories !== prevState.categories) {
      return { categories: nextProps.categories };
    }
    if (nextProps.cart !== prevState.cart) {
      return { cart: nextProps.cart };
    }
    if (nextProps.db_currentUser !== prevState.db_currentUser) {
      // Once a user is registered and received, call for updating the cart.
      nextProps.getCart(nextProps.db_currentUser);
      return { mem_id: nextProps.db_currentUser.mem_id };
    }
    return null;
  }

  render() {
    let items = this.state.cart;
    return (
      <div className="Cart-content">
        <SectionHeader title={"Your Cart (" + items.length + " items)"} />
        {items.map((item, i) => (
          <CartCard
            key={i}
            id={item.car_id}
            imgSrc={item.pro_thumbnail}
            title={
              item.pro_name + " - signature select juice apple cider bottle"
            }
            weight="10oz"
            price="$0.99"
            qty={item.pro_quantity}
            router={this.props.router}
          />
        ))}
        <SectionHeader title="Cart Summary" />
        <CartSummary />
        <SectionHeader title="Add Related Products" />
        <FeaturedContainer categories={this.props.categories} />
      </div>
    );
  }
}
export default Cart;
