import React, { Component } from "react";
import { Animated } from "react-animated-css";
import HomeBanner from "../components/Home/HomeBanner.jsx";
import SectionHeader from "../components/Common/SectionHeader.jsx";
import FeaturedContainer from "../components/Common/FeaturedContainer.jsx";
import CategoryContainer from "../components/Common/CategoryContainer.jsx";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: [
        "/assets/images/banner/test.jpg",
        "/assets/images/banner/zionbanner.jpg"
      ],
      readyToRender: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    //Get categories
    this.props.modalToggle(true, "");
    this.props.getCategories();
    // setTimeout(
    //   () =>
    //     this.props.notificationToggle({
    //       currentlyShowing: false,
    //       msg: "Welcome to eZion Market!",
    //       type: "success"
    //     }),
    //   2000
    // );
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.categories !== prevState.categories) {
      return { categories: nextProps.categories, readyToRender: true };
    }
    return null;
  }
  render() {
    let items = this.state.categories;
    return (
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={this.state.ready}
      >
        <div className="Home-content">
          <HomeBanner imgSrc={this.state.imgSrc} />
          <SectionHeader title="Featured Products" />
          <FeaturedContainer {...this.props} />
          <SectionHeader title="Category" />
          <CategoryContainer categories={items} router={this.props.router} />
        </div>
      </Animated>
    );
  }
}
export default Home;
