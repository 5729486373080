import firebase from "firebase/app";

const config = {
  apiKey: "AIzaSyCzKLVlCM3k5qQnQxZC5YUMpdYIQOFZQi4",
  authDomain: "ezreact-b5bf9.firebaseapp.com",
  databaseURL: "https://ezreact-b5bf9.firebaseio.com",
  projectId: "ezreact-b5bf9",
  storageBucket: "ezreact-b5bf9.appspot.com",
  messagingSenderId: "374501564381"
};

const fire = firebase.initializeApp(config);

export default fire;
