import React from "react";
import { Link } from "react-router";
import FullButton from "./FullButton.jsx";
import "../../css/LoginModal.css";
import { faGoogle, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", pw: "" };
  }
  componentDidMount() {}
  handleLog = () => {
    let data = this.state;
    this.props.SigninLocal(data);
  };
  render() {
    const { modalStatus, modalToggle, modalType } = this.props;
    const showModalContent =
      modalStatus && modalType === "login"
        ? "LoginModal-container"
        : "LoginModal-container shrink";
    return (
      <div className={showModalContent}>
        <div className="input-wrapper">
          <input
            className="input-full text-small rounded input-grey"
            type="email"
            placeholder="Email address"
            value={this.state.email}
            onFocus={e => (e.target.placeholder = "")}
            onChange={e => this.setState({ email: e.target.value })}
          />
        </div>
        <div className="input-wrapper">
          <input
            className="input-full text-small rounded input-grey"
            type="password"
            placeholder="Password"
            value={this.state.pw}
            onFocus={e => (e.target.placeholder = "")}
            onChange={e => this.setState({ pw: e.target.value })}
          />
        </div>
        <FullButton
          color="white"
          classList="btn btn-full rounded btn-xen"
          btnText="Log in"
          action={this.handleLog}
        />
        <div className="horiz-line text-small ">or</div>
        <FullButton
          icon={faFacebookSquare}
          color="white"
          classList="btn btn-full rounded btn-fb"
          btnText="Log in w/ Facebook"
          action={this.props.SigninFacebook}
        />
        <FullButton
          color="white"
          icon={faGoogle}
          classList="btn btn-full rounded btn-google"
          btnText="Log in w/ Google"
          action={this.props.SigninGoogle}
        />
        <div style={{ textAlign: "center" }}>
          <Link className="LoginModal-link text-small" to="/create-account">
            Don't have an account?
          </Link>
          <br />
          <Link className="LoginModal-link text-small" to="#">
            Forgot your password?
          </Link>
        </div>

        <div
          className="close-modal log-close"
          onClick={() => modalToggle(true, "")}
        >
          <span>&times;</span>
        </div>
      </div>
    );
  }
}
export default LoginModal;
