import axios from "axios";
import { DEFAULT_API_URL } from "../constants";
import { browserHistory } from "react-router";
import firebase from "firebase/app";
import "firebase/auth";

export const sampleAction = () => dispatch => {
  dispatch({
    type: "SAMPLE_ACTION",
    payload: "result yielded."
  });
};
export const sampleFetch = () => async dispatch => {
  await fetch(DEFAULT_API_URL + "test", {
    method: "POST",
    headers: {
      Accept: "application/json"
      // "Content-Type": "application/json"
    },
    body: JSON.stringify({
      token: "test"
    })
  })
    .then(response => response.json())
    .then(json => {
      console.log(json);
      dispatch({
        type: "SAMPLE_FETCH",
        payload: json
      });
    })
    .catch(error => {
      console.log(error);
      console.log("error");
    });
};
export const sampleAxios = () => dispatch => {
  axios.get("https://jsonplaceholder.typicode.com/todos").then(response => {
    dispatch({
      type: "SAMPLE_AXIOS",
      payload: response.data
    });
  });
};
export const getCategories = () => async dispatch => {
  await fetch(DEFAULT_API_URL + "get-categories", {
    method: "GET",
    headers: {
      Accept: "application/json"
    }
  })
    .then(response => response.json())
    .then(json => {
      console.log(json);
      dispatch({
        type: "FETCH_CATEGORIES_COMPLETED",
        payload: json.categories
      });
    })
    .catch(error => {
      console.log(error);
      console.log("error");
    });
};
export const getCart = id => async dispatch => {
  console.log(id);
  await fetch(DEFAULT_API_URL + "get-cart", {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: JSON.stringify(id)
  })
    .then(response => response.json())
    .then(json => {
      console.log(json);
      dispatch({
        type: "FETCH_CART_COMPLETED",
        payload: json.cart
      });
    })
    .catch(error => {
      console.log(error);
      console.log("error");
    });
};
export const addToCart = data => async dispatch => {
  console.log(data);
  console.log("ADDING TO CART");
  await fetch(DEFAULT_API_URL + "add-to-cart", {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(json => {
      if (json.status) {
        dispatch({
          type: "ADDED_TO_CART",
          payload: true
        });
        dispatch(
          notificationToggle({
            currentlyShowing: false,
            msg: "Item added to cart!",
            type: "success"
          })
        );
      } else {
        dispatch(
          notificationToggle({
            currentlyShowing: false,
            msg: json.message,
            type: "danger"
          })
        );
      }
    })
    .catch(error => {
      console.log(error);
      console.log("error");
    });
};
export const getProductList = () => async dispatch => {
  // TODO: make 'category / filter / subcategory' a parameter.
  await fetch(DEFAULT_API_URL + "get-product-list", {
    method: "GET",
    headers: {
      Accept: "application/json"
    }
  })
    .then(response => response.json())
    .then(json => {
      console.log(json);
      dispatch({
        type: "FETCH_PRODUCTLIST_COMPLETED",
        payload: json.productlist
      });
    })
    .catch(error => {
      console.log(error);
      console.log("error");
    });
};
export const modalToggle = (currentStatus, type) => dispatch => {
  if (currentStatus) {
    dispatch({
      type: "SWITCH_MODAL_OFF",
      payload: { status: false, type: "" }
    });
  } else {
    dispatch({
      type: "SWITCH_MODAL_ON",
      payload: { status: true, type: type }
    });
  }
};
export const notificationToggle = currentStatus => dispatch => {
  if (currentStatus.currentlyShowing) {
    dispatch({
      type: "SWITCH_NOTIFICATION_OFF",
      payload: { show: false, msg: "" }
    });
  } else {
    //If it wasnt showing already, toggle notification on, then set a timer to automatically disappear.
    dispatch({
      type: "SWITCH_NOTIFICATION_ON",
      payload: { show: true, msg: currentStatus.msg, type: currentStatus.type }
    });
    setTimeout(
      () =>
        dispatch({
          type: "SWITCH_NOTIFICATION_OFF",
          payload: { show: false, msg: "" }
        }),
      3000
    );
  }
};
export const checkSession = () => dispatch => {
  let firebaseUser = firebase.auth().currentUser;

  let sessionUser = JSON.parse(localStorage.getItem("user"));
  // console.log(sessionUser);
  if (sessionUser) {
    dispatch({
      type: "SESSION_EXISTS",
      payload: { dbUser: sessionUser }
    });
    console.log(
      "Auth status changed: local logged in as: " + sessionUser.email
    );
  }
  if (!firebaseUser && !sessionUser) {
    dispatch({ type: "SESSION_NULL", payload: "" });
    console.log("Auth status changed: not logged in.");
    console.log("---1----");
    const loc = browserHistory.getCurrentLocation().pathname;
    console.log(loc);
    // if (loc !== "/" || loc !== "/create-account") {
    // browserHistory.push("/");
    // dispatch(
    //   notificationToggle({
    //     currentlyShowing: false,
    //     msg: "You must login first!",
    //     type: "danger"
    //   })
    // );
    // dispatch(modalToggle(false, "login"));
    // }
  }
};
export const signOut = () => dispatch => {
  firebase
    .auth()
    .signOut()
    .then(function(result) {
      dispatch({ type: "SESSION_NULL", payload: "" });
      dispatch({
        type: "SWITCH_MODAL_OFF",
        payload: false
      });
    });
  //signs out current user
  localStorage.removeItem("user");
  browserHistory.push("/");
};
export const SigninLocal = params => async dispatch => {
  await fetch(DEFAULT_API_URL + "login-check", {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: JSON.stringify(params)
  })
    .then(response => response.json())
    .then(json => {
      if (json.status) {
        localStorage.setItem("user", JSON.stringify(json));
        dispatch({
          type: "LOCAL_ACCOUNT_SUCCESS",
          payload: { dbUser: json }
        });
        dispatch(checkSession());
        dispatch({
          type: "SWITCH_MODAL_OFF",
          payload: { status: false, type: "login" }
        });
      } else {
        dispatch(
          notificationToggle({
            currentlyShowing: false,
            msg: json.message,
            type: "danger"
          })
        );
      }
    })
    .catch(error => {
      dispatch(
        notificationToggle({
          currentlyShowing: false,
          msg: "An error has occurred",
          type: "danger"
        })
      );
      console.log(JSON.stringify(error));
    });
};
export const sendUser = (params, user) => async dispatch => {
  await fetch(DEFAULT_API_URL + "signup-check", {
    method: "POST",
    headers: {
      Accept: "application/json"
      // "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  })
    .then(response => response.json())
    .then(json => {
      console.log("-----");
      console.log(json);
      console.log("-----");
      if (json.status) {
        // Save user data to localStorage
        localStorage.setItem("user", JSON.stringify(json));
        if (params.user_origin === "google") {
          dispatch({
            type: "GOOGLE_CREATE_ACCOUNT_SUCCESS",
            payload: { dbUser: json }
          });
        } else if (params.user_origin === "facebook") {
          dispatch({
            type: "FACEBOOK_CREATE_ACCOUNT_SUCCESS",
            payload: { dbUser: json }
          });
        } else {
          dispatch({ type: "LOCAL_CREATE_ACCOUNT", payload: "" });
          dispatch({
            type: "LOCAL_ACCOUNT_SUCCESS",
            payload: { dbUser: json }
          });
          browserHistory.goBack();
        }
        dispatch(checkSession());
      } else {
        dispatch(
          notificationToggle({
            currentlyShowing: false,
            msg: json.message,
            type: "danger"
          })
        );
      }
    })
    .catch(error => {
      console.log(error);
      if (params.user_origin === "google") {
        dispatch({
          type: "GOOGLE_CREATE_ACCOUNT_ERROR",
          payload: error
        });
      } else if (params.user_origin === "facebook") {
        dispatch({
          type: "FACEBOOK_CREATE_ACCOUNT_ERROR",
          payload: error
        });
      }
      dispatch(
        notificationToggle({
          currentlyShowing: false,
          msg: error,
          type: "danger"
        })
      );
    });
};

export const SigninGoogle = () => dispatch => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function(result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // let token = result.credential.accessToken;
      // The signed-in user info.
      let user = result.user;
      let data_obj = {
        user_id: user.providerData[0].uid,
        user_first: "",
        user_last: "",
        user_email: user.providerData[0].email,
        user_zipcode: "0",
        is_social: 1,
        user_origin: "google"
      };

      console.log(user);
      dispatch(sendUser(data_obj, user));
      dispatch({
        type: "SWITCH_MODAL_OFF",
        payload: false
      });
      //Register user to DB or return credentials
      // let user_info = sendUser_(data_obj);

      browserHistory.push("/");
    })
    .catch(function(error) {
      // Handle Errors here.
      // var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorMessage);
      let currentStatus = {
        currentlyShowing: false,
        type: "danger",
        msg: errorMessage
      };
      dispatch(
        notificationToggle({
          currentlyShowing: false,
          msg: currentStatus.msg,
          type: currentStatus.type
        })
      );
      // The email of the user's account used.
      // var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      dispatch({ type: "GOOGLE_CREATE_ACCOUNT_ERROR", payload: errorMessage });
    });
};

export const SigninFacebook = () => dispatch => {
  dispatch({ type: "FACEBOOK_CREATE_ACCOUNT", payload: "" });
  const provider = new firebase.auth.FacebookAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function(result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // let token = result.credential.accessToken;
      // The signed-in user info.
      let user = result.user;
      let data_obj = {
        user_id: user.providerData[0].uid,
        user_first: "",
        user_last: "",
        user_email: user.providerData[0].email,
        user_zipcode: "0",
        is_social: 1,
        user_origin: "facebook"
      };
      dispatch({
        type: "SWITCH_MODAL_OFF",
        payload: false
      });
      dispatch(sendUser(data_obj, user));
      // TODO: make a post request to the users DB if this is a new user.
    })
    .catch(function(error) {
      // Handle Errors here.
      // var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorMessage);
      let currentStatus = {
        currentlyShowing: false,
        type: "danger",
        msg: errorMessage
      };
      dispatch(
        notificationToggle({
          currentlyShowing: false,
          msg: currentStatus.msg,
          type: currentStatus.type
        })
      );

      // The email of the user's account used.
      // var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      dispatch({
        type: "FACEBOOK_CREATE_ACCOUNT_ERROR",
        payload: errorMessage
      });
    });
};
