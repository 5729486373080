import React from "react";
import "../../css/CartSummary.css";
import FullButton from "../Common/FullButton.jsx";

export default class CartSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { qty: 1 };
  }
  render() {
    return (
      <div className="cartSummary-container">
        <table className="cartSummary-table">
          <tbody>
            <tr>
              <td>Subtotal (17 items)</td>
              <td>$34.66</td>
            </tr>
            <tr>
              <td className="green">
                Add $15.34 more for FREE Delivery <span>&#9432;</span>
              </td>
              <td className="green">-$4.00</td>
            </tr>
            {/* <tr>
              <td />
              <td />
            </tr>
            <tr>
              <td>
                Estimated Total <span>&#9432;</span>
              </td>
              <td>$30.66</td>
            </tr> */}
          </tbody>
        </table>
        <FullButton
          // icon={faFacebookSquare}
          classList="btn btn-full btn-xen rounded"
          btnText="CHECKOUT"
          action={this.props.SigninFacebook}
        />
      </div>
    );
  }
}
