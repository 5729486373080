import React from "react";
import ProductCard from "./ProductCard.jsx";
import "../../css/ProductsContainer.css";
class ProductsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { products: [] };
  }
  componentDidMount() {}
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.productlist !== prevState.productlist) {
      return { products: nextProps.productlist };
    }
    return null;
  }

  render() {
    return (
      <div className="ProductsContainer-container">
        {this.state.products.map((index, i) => (
          <ProductCard
            imgSrc={index.pro_thumbnail}
            id={index.pro_id}
            key={i}
            price="$3.99"
            perunit=" / ea"
            weight="9 oz"
            deliveryOnly="true"
            title={index.pro_name}
            description="cut off to dotdotdot when 3 lines over"
            router={this.props.router}
            addToCart={this.props.addToCart}
            currentUser={this.props.db_currentUser}
          />
        ))}
      </div>
    );
  }
}
export default ProductsContainer;
